module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleTagManager":{"trackingId":"GTM-NNL7GF8V","cookieName":"gatsby-gdpr-gtm","dataLayerName":"dataLayer"},"environments":["production","development"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"VirtualZ","short_name":"VirtualZ","start_url":"/","background_color":"#009B90","theme_color":"#009B90","display":"minimal-ui","icon":"src/images/Large-Black-Blue-VZ.png","icon_options":{"purpose":"any maskable"},"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"7d88cb782f40f0bb654da5258ef72dcf"},
    },{
      plugin: require('../node_modules/gatsby-plugin-prismic-previews/gatsby-browser.js'),
      options: {"plugins":[],"accessToken":"MC5aUXNYWmhBQUFDUUEyRHQ0.Qi3vv73vv73vv73vv73vv73vv71PUyNv77-9TGPvv700Ou-_vQZmS33vv73vv73vv73vv73vv71LPT5P","repositoryName":"virtualz","fetchLinks":["page.parent"],"routes":[{"type":"page","uid":"index","path":"/:lang?/"},{"type":"page","resolvers":{"parent":"parent"},"path":"/:lang?/:parent?/:uid/"},{"type":"case_study","path":"/:lang?/blog/:uid/"}]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
